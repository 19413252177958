import type { IConfirmationModal } from "./type";

import { FC } from "react";
import useDarkMode from "use-dark-mode";

import { Loader } from "components";
import { CANCEL_ORDER_BTN } from "./constant";

import styles from "./confirmation.module.sass";

import cancelDark from "assets/images/cancel-dark.svg";
import cancelLight from "assets/images/cancel-light.svg";

export const Confirmation: FC<IConfirmationModal> = ({
  visible,
  title,
  description,
  handleModal,
  handleClose,
  boldDescription = "",
  label,
  cancelLoading = false,
  type,
  cancelLabel,
  confirmationHeaderStyle,
  isEnableJsxElement = false,
  confirmationJsxElement
}) => {
 const darkMode = useDarkMode(false);

  const { CANCEL_NO, CANCEL_YES } = CANCEL_ORDER_BTN;

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.confirmationModal}>
      <div
        className={styles.confirmationHeader}
        style={confirmationHeaderStyle}
      >
        <img
          src={darkMode.value ? cancelDark : cancelLight}
          alt=""
          className={styles.confirmationImage}
        />
        <div className={styles.confirmationTitle}>{title}</div>
      </div>
      <div>
        <div className={styles.modalDes}>
          <span>
            {description} <strong>{boldDescription}</strong>
          </span>
          {isEnableJsxElement && <div>{confirmationJsxElement}</div>}
      
        </div>
        <div className={styles.modalFooter}>
          <button
            className={styles.buttonCancel}
            onClick={handleClose}
            disabled={cancelLoading}
          >
            {cancelLabel ?? CANCEL_NO}
          </button>
          <button
            className={`${styles.buttonCancelYes} ${
              cancelLoading ? styles.loadingActive : ""
            }  ${type === "buy" ? styles.btnbuy : ""}  ${
              type === "success" ? styles.btnSuccess : ""
            }`}
            onClick={handleModal}
            disabled={cancelLoading}
          >
            <span className={`${styles.btnText}`}>{label ?? CANCEL_YES}</span>
            <i className={`${styles.loaderIcon}`}>
            <Loader className="loader-white" dimension={26} />
            </i>
          </button>
        </div>
      </div>
    </div>
  );
};
