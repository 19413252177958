import { getApp, getApps, initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging } from "firebase/messaging";
import {
  getAuth,
  setPersistence,
  signInWithCustomToken,
  browserLocalPersistence,
} from "firebase/auth"; // Import Firebase Auth functions
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { REACT_APP_FIREBASE as FIREBASE ,ENVIRONMENT} from "envs"; // Assuming REACT_APP_FIREBASE contains your Firebase config
import { CLIENT_ENVIRONMENT } from "@routes/constants";
import { initAnalytics } from "./segment";

// Parse your Firebase config
const firebaseConfig = JSON.parse(FIREBASE);

// Initialize Firebase App (Singleton pattern to ensure no re-initialization)
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

const analytics: any =
  ENVIRONMENT === CLIENT_ENVIRONMENT.prod ? getAnalytics(app) : {};

const segementAnalytics = ENVIRONMENT === CLIENT_ENVIRONMENT.prod ? initAnalytics():{};

// Initialize Firebase services (Database, Firestore, Messaging)
const db = getDatabase(app);
const firestoreDb = getFirestore(app);
let messaging: any = null;

if (typeof window !== "undefined" && "serviceWorker" in navigator) {
  messaging = getMessaging(app);
}

// Function to sign in with custom token and set persistence
export const signInWithTokenAndSetPersistence = async (customToken: string) => {
  const auth = getAuth(app); // Initialize Firebase Auth
  try {
    // Set persistence to browserLocalPersistence (so user stays signed in across browser sessions)
    await setPersistence(auth, browserLocalPersistence);

    // Sign in with custom token
    const userCredential = await signInWithCustomToken(auth, customToken);

    console.log("User signed in successfully", userCredential.user);

    // You can optionally return the user object or token
    const token = await userCredential.user.getIdToken();
    return { user: userCredential.user, token };
  } catch (error) {
    console.error("Error during sign-in or setting persistence:", error);
    throw error; // You can handle this error in the UI or log it
  }
};

export { messaging, firestoreDb, db, analytics, segementAnalytics };
